import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { VBtn, VIcon } from 'vuetify/lib';

@Component({ components: { VIcon, VBtn } })
export class Button extends Vue {
  text = '';
  icon = ''
  @Prop({ default: 'btn' }) type!: 'text' | 'text-icon' | 'btn' | 'icon' | 'icon-btn';
  @Prop({ type: Boolean, default: false }) small!: boolean;
  @Prop() to?: RawLocation;
  @Prop() btnText!: boolean;
  @Prop() color!: string;

  render() {
    const icon = <v-icon small={this.small}>{this.icon}</v-icon>;

    if (this.type === 'text' || this.type === 'text-icon') {
      return (
        <span>{this.type === 'text-icon' && icon}<span class="ml-2">{this.text}</span></span>);
    }
    if (this.type === 'icon') {
      return icon;
    }

    return (
      <v-btn
        to={this.to}
        color={this.color}
        small={true}
        text={true}
        icon={true}
      >
        {icon}
        {this.type === 'btn' ? <span class="ml-2">this.text</span> : ''}
      </v-btn>
    );
  }
}
