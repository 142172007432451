















































import { Component, Inject, Vue } from 'vue-property-decorator';
import { AppModule } from '@/store/app/AppModule';
import { Context } from 'vuex-smart-module';
import PolygonCard from '@/views/polygons/components/PolygonCard.vue';
import PageHeader1 from '@/components/PageHeader1.vue';

@Component({
  components: { PageHeader1, PolygonCard },
})
export default class PolygonsListView extends Vue {
  @Inject() appStore!: Context<AppModule>;
  isLoading: boolean = false;

  async mounted() {
    if (!this.polygons?.length) {
      this.isLoading = true;
    }
    await this.appStore.actions.fetchAvailablePolygons();
    this.isLoading = false;
  }

  get polygons() {
    return this.appStore.getters?.availablePolygons || [];
  }

  get isSuperAdmin() {
    return this.appStore.getters.profile?.isSuperAdmin;
  }
}
