









































































































import {
  Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { Polygon } from '@/models/entities/Polygon';
import PolygonScheduleBtn from '@/views/polygons/components/buttons/PolygonScheduleBtn.vue';
import PolygonCamerasBtn from '@/views/polygons/components/buttons/PolygonCamerasBtn.vue';
import PolygonStatBtn from '@/views/polygons/components/buttons/PolygonStatBtn.vue';
import PolygonManageBtn from '@/views/polygons/components/buttons/PolygonManageBtn.vue';
import PolygonUsersBtn from '@/views/polygons/components/buttons/PolygonUsersBtn.vue';
import { ApiFacade } from '@/services/ApiFacade';
import { UserRoleIds } from '@/types/UserRoleIds';
import UserAvatar from '@/views/profile/components/UserAvatar.vue';

@Component({
  components: {
    UserAvatar,
    PolygonUsersBtn,
    PolygonManageBtn,
    PolygonStatBtn,
    PolygonCamerasBtn,
    PolygonScheduleBtn,
  },
})
export default class PolygonCard extends Vue {
  @Prop({ required: true }) private polygon!: Polygon;
  @Inject() readonly apiFacade!: ApiFacade;

  get admins() {
    return this.polygon.userRolePolygons
      .filter((ur) => ur.userRole.userRoleId === UserRoleIds.admin)
      .map((ur) => ur.user);
  }

  get teachers() {
    return this.polygon.userRolePolygons
      .filter((ur) => ur.userRole.userRoleId === UserRoleIds.teacher)
      .map((ur) => ur.user);
  }
}
